import React, {FunctionComponent, Suspense, useEffect, useState} from 'react';
import {BrowserRouter, Routes} from "react-router-dom";
import {logErrorToBackend} from "./utils/erroryLoggingUtils";
import {getCookieConsentValue} from "react-cookie-consent";
import {initGA} from "./utils/GAUtils";
import {ConsentCookiesProvider, removePersonalCookie} from "./contexts/CookieSettingsContext/CookieSettingsContext";
import {optInMixPanel} from "./utils/MixPanelUtils";
import mixpanel from "mixpanel-browser";
import {ThemePreferenceProvider, useThemePreference} from "./contexts/ThemePreferenceContext/ThemePreferenceProvider";
import {getArtistreeMaterialUIStyling} from "./artistree/artistreeStyles/ArtistreeMaterialUIStyling";
import {getHumanMaterialUIStyling} from "./human/humanStyles/HumanMaterialUIStyling";
import {ThemeProvider} from "@material-ui/core/styles";
import {toRoute} from "./utils/RouterUtils";
import LoadingGif from "./components/LoadingGif/LoadingGif";
import {lazyWithRetry} from "./utils/LazyLoadingUtils";
import "./App.scss";
import "./styles/Layout.scss";
import "./styles/Styling.scss";

if (process.env.NODE_ENV === 'production') {
  console.log = function () {
  };
}

const HumanRoutesComp = lazyWithRetry(() => import("./human/humanComponents/HumanRoutes/HumanRoutes"));
const ArtistreeRoutesComp = lazyWithRetry(() => import("./artistree/artistreeComponents/ArtistreeRoutes/ArtistreeRoutes"));


mixpanel.init(process.env['REACT_APP_MIXPANEL_TOKEN'] as string);

const App: FunctionComponent<{}> = () => {

  const [isHumanSite, setIsHumanSite] = useState<boolean>(window.location.origin.includes("human"));

  useEffect(() => {
    if (getCookieConsentValue() === "true") {
      initGA();
      optInMixPanel();
    } else {
      // this is done here because, I believe, there is a delay of when Google's GA stops working
      // so doing this right after the button is clicked isn't working
      removePersonalCookie();
    }
  }, [])

  const ThemeProviderWrapper = (props: { children: any; }) => {
    const {children} = props;
    const {themePreference, isDarkTheme} = useThemePreference()

    return <ThemeProvider theme={isHumanSite ? getHumanMaterialUIStyling(themePreference, true) :
      getArtistreeMaterialUIStyling(themePreference, isDarkTheme())}>
      {children}
    </ThemeProvider>
  }


  const ArtistreeRoutes = toRoute({route: "/*", element: <ArtistreeRoutesComp/>}, 0)
  const HumanRoutes = toRoute({route: "/*", element: <HumanRoutesComp/>}, 0)


  return (
    <React.StrictMode>
      <ThemePreferenceProvider>
        <BrowserRouter>
          <ThemeProviderWrapper>
            <ConsentCookiesProvider>
              <Suspense fallback={<LoadingGif/>}>
                <Routes>
                  {isHumanSite ? HumanRoutes : ArtistreeRoutes}
                </Routes>
              </Suspense>
            </ConsentCookiesProvider>
          </ThemeProviderWrapper>
        </BrowserRouter>
      </ThemePreferenceProvider>
    </React.StrictMode>
  )
}

export default App;

// human routes are using Sentry, no need to use this
if (!window.location.origin.includes("human")) {
  window.onunhandledrejection = (event: PromiseRejectionEvent) => {

    const error = event.reason;

    if (!error) {
      // Nothing to report
      return;
    }

    // @ts-ignore
    const errorPathname = event.target?.location?.pathname;

    // @ts-ignore
    if (error.name === "RethrownError") {
      logErrorToBackend(error, {componentStack: error.original_error.stack}, "rethrow error uncaught promise at: " + window.location.origin + errorPathname);
      return;
    }

    logErrorToBackend(error, {componentStack: event.promise}, "uncaught promise at: " + window.location.origin + errorPathname);
  };
  window.onerror = (message, source, lineno, colno, error) => {
    logErrorToBackend(error, {message, source, lineno, colno}, "uncaught error at: " + window.location.origin + window.location.pathname);
  }
}
