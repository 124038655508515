import {getMixPanelId} from "./MixPanelUtils";
import axios, {AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse} from "axios";
import {stringify} from "flatted";
import {AuthSession, getCurrentUser} from "aws-amplify/auth";

const BASE_PATH = typeof window != "undefined" && window.location.origin.includes("human") ?
  process.env["REACT_APP_HUMAN_API_BASE_PATH"]
  : process.env['REACT_APP_ARTISTREE_API_BASE_PATH'] + "/"

export async function fetchGetResponse<T extends any>(path: string, authSession?: AuthSession | string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return axios.get<T>(BASE_PATH + path, {
    ...config,
    headers: await getHeaders(authSession),
  });
}

export function fetchGetBody<T extends any>(path: string, authSession?: AuthSession | string): Promise<T> {
  return fetchGetResponse<T>(path, authSession)
    .then((res) => res.data)
    .catch(reThrowErrorWithPath(path));
}

export async function fetchPostResponse<T extends any>(path: string, body: any, authSession?: AuthSession): Promise<AxiosResponse<T>> {
  return axios.post<T>(BASE_PATH + path, JSON.stringify(body), {
    headers: await getHeaders(authSession),
  });
}

export function fetchPostBody<T extends any>(path: string, body: any, authSession?: AuthSession): Promise<T> {
  return fetchPostResponse<T>(path, body, authSession)
    .then((res) => res.data)
    .catch(reThrowErrorWithPath(path));
}


export async function fetchDeleteResponse<T extends any>(path: string, authSession?: AuthSession): Promise<AxiosResponse<T>> {
  return axios.delete<T>(BASE_PATH + path, {
    headers: await getHeaders(authSession),
  });
}


export function fetchDeleteBody<T extends any>(path: string, authSession?: AuthSession): Promise<T> {
  return fetchDeleteResponse<T>(path, authSession)
    .then((res) => res.data)
    .catch(reThrowErrorWithPath(path));
}

export async function fetchPutResponse<T extends any>(path: string, body: any, authSession?: AuthSession): Promise<AxiosResponse<T>> {
  return axios.put<T>(BASE_PATH + path, JSON.stringify(body), {
    headers: await getHeaders(authSession),
  })
}

export function fetchPutBody<T extends any>(path: string, body: any, authSession?: AuthSession): Promise<T> {
  return fetchPutResponse<T>(path, body, authSession)
    .then((res) => res.data)
    .catch(reThrowErrorWithPath(path));
}

export function fetchPutUpload(url: string, contentType: string, body: any, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void): Promise<any> {
  return axios.put(url, body, {
    headers: {
      "Content-Type": contentType,
    },
    onUploadProgress: onUploadProgress,
  }).catch(reThrowErrorWithPath(url));
}

export function fetchPutImageUpload(url: string, imageFile: File, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void): Promise<any> {
  return fetchPutUpload(url, imageFile.type, imageFile, onUploadProgress);
}

async function getHeaders(authSession?: AuthSession | string): Promise<Record<string, string>> {
  const headers = {
    "Content-Type": "application/json",
    "Client-Id": getMixPanelId(),
  };

  if (typeof authSession === "string") {
    headers["Authorization"] = authSession;
  } else if (authSession && authSession.tokens) {
    headers["Authorization"] = authSession.tokens.idToken;
  }
  const authId: string | undefined = await getCurrentUser()
    .then(({username}) => username)
    .catch(err => undefined)

  if (authId) {
    headers["Auth-Id"] = authId;
  }

  return headers;
}

export function reThrowErrorWithPath(path: string) {
  return (error: AxiosError<any>) => {
    let errorStack = "undefined stack";
    if (error.stack) {
      errorStack = error.stack;
    }

    throw Error(stringify(errorStack) + " failed path: " + path)
  };
}