import React, {FunctionComponent, useEffect, useState} from "react";
import "./LoadingGif.scss";
import {ReactComponent as LogoSmall} from "../../assets/logo-small.svg";
import {ReactComponent as HumanLogoSmall} from "../../assets/human-dark-logo-small.svg";

type LoadingGifProps = {
  errorText?: JSX.Element,
  passedInText?: JSX.Element,
  overrideLoadingText?: string,
  fullScreen?: boolean,
}

const LoadingGif: FunctionComponent<LoadingGifProps> = (loadingGifProps) => {
  const {
    errorText,
    passedInText,
    overrideLoadingText,
    fullScreen,
  } = loadingGifProps;
  const [loadingText, setLoadingText] = useState<string>(overrideLoadingText || "Loading...");
  const [isHumanSite, setIsHumanSite] = useState<boolean>(window.location.origin.includes("human"));

  useEffect(() => {

  }, []);

  function getLoadingText() {
    if (errorText) {
      return <div className="center-text padding-top"
                  style={{marginBottom: "auto"}}>
        {errorText}
      </div>
    } else if (passedInText) {
      return <div className="center-text padding-top"
                  style={{marginBottom: "auto"}}>
        {passedInText}
      </div>
    } else {
      return <h5 className="loading-text center-text max-small-text-width">{loadingText}</h5>;
    }
  }

  const baseClazzName = "loading-container flex-col"

  return (
    <div className={fullScreen === undefined || fullScreen ? "full-screen-loading-container " + baseClazzName : baseClazzName}>
      {isHumanSite ? <HumanLogoSmall id="loading-logo-id" className="loading-logo"/> : <LogoSmall id="loading-logo-id" className="loading-logo"/>}
      {getLoadingText()}
    </div>
  )
}

export default LoadingGif;